/**
 * Static actions goes here.
 * We can add static actions which will be used by Reducer dispatch function
 */

export const GET_WELLKNOWNCONFIG_REQUEST_SENT = 'GET_WELLKNOWNCONFIG_REQUEST_SENT'
export const GET_WELLKNOWNCONFIG_RECVD = 'GET_WELLKNOWNCONFIG_RECVD'

export const AUTHENTICATE_USER_REQUEST_SENT = 'AUTHENTICATE_USER_REQUEST_SENT'
export const AUTHENTICATE_USER_RECVD = 'AUTHENTICATE_USER_RECVD'


export const LOGOUT_USER_REQUEST_SENT = 'LOGOUT_USER_REQUEST_SENT'
export const LOGOUT_USER_RECVD = 'LOGOUT_USER_RECVD'

export const AUTHENTICATE_SET_RESETSESSIONTIMER = 'AUTHENTICATE_SET_RESETSESSIONTIMER'

export const GET_SESSION_INFO_REQUEST_SENT = 'GET_SESSION_REQUEST_SENT'
export const GET_SESSION_INFO_RECVD = 'GET_SESSION_RECVD'

export const GET_USERS_REQUEST_SENT = 'GET_USERS_REQUEST_SENT'
export const GET_USERS_RECVD = 'GET_USERS_RECVD'

export const GET_USER_REQUEST_SENT = 'GET_USER_REQUEST_SENT'
export const GET_USER_RECVD = 'GET_USER_RECVD'

export const GET_USER_ROLES_REQUEST_SENT = 'GET_USER_ROLES_REQUEST_SENT'
export const GET_USER_ROLES_RECVD = 'GET_USER_ROLES_RECVD'

export const KEEPALIVE_REQUEST_SENT = 'KEEPALIVE_REQUEST_SENT'
export const KEEPALIVE_RECVD = 'KEEPALIVE_RECVD'

export const GET_COMPANY_ROLES_REQUEST_SENT = 'GET_COMPANY_ROLES_REQUEST_SENT'
export const GET_COMPANY_ROLES_RECVD = 'GET_COMPANY_ROLES_RECVD'

export const GET_AVAILABLE_COMPANY_ROLES_REQUEST_SENT = 'GET_AVAILABLE_COMPANY_ROLES_REQUEST_SENT'
export const GET_AVAILABLE_COMPANY_ROLES_RECVD = 'GET_AVAILABLE_COMPANY_ROLES_RECVD'

export const GET_APPLICATION_ROLES_REQUEST_SENT = 'GET_APPLICATION_ROLES_REQUEST_SENT'
export const GET_APPLICATION_ROLES_RECVD = 'GET_APPLICATION_ROLES_RECVD'

export const GET_COMPANIES_REQUEST_SENT = 'GET_COMPANIES_REQUEST_SENT'
export const GET_COMPANY_REQUEST_SENT = 'GET_COMPANY_REQUEST_SENT'
export const GET_ALTERNATEIDS_REQUEST_SENT = 'GET_ALTERNATEIDS_REQUEST_SENT'
export const GET_COMPANIES_RECVD = 'GET_COMPANIES_RECVD'
export const GET_COMPANY_RECVD = 'GET_COMPANY_RECVD'
export const GET_ALTERNATEIDS_RECVD = 'GET_ALTERNATEIDS_RECVD'
export const GET_USERREPORT_REQUEST_SENT = 'GET_USERREPORT_REQUEST_SENT'
export const GET_USERREPORT_RECVD = 'GET_USERREPORT_RECVD'

export const ADD_UPDATE_USER_REQUEST_SENT = 'ADD_UPDATE_USER_REQUEST_SENT'
export const ADD_UPDATE_USER_RECVD = 'ADD_UPDATE_USER_RECVD'
export const ADD_UPDATE_USER_FINISHED = 'ADD_UPDATE_USER_FINISHED'

export const ADD_UPDATE_COMPANY_REQUEST_SENT = 'ADD_UPDATE_COMPANY_REQUEST_SENT'
export const ADD_COMPANY_RECVD = 'ADD_COMPANY_RECVD'
export const UPDATE_COMPANY_RECVD = 'UPDATE_COMPANY_RECVD'

export const VALIDATE_ALTERNATEID_REQUEST_SENT = 'VALIDATE_ALTERNATEID_REQUEST_SENT'
export const VALIDATE_ALTERNATEID_RECVD = 'VALIDATE_ALTERNATEID_RECVD'

export const VALIDATE_COMPANYNAME_REQUEST_SENT = 'VALIDATE_COMPANYNAME_REQUEST_SENT'
export const VALIDATE_COMPANYNAME_RECVD = 'VALIDATE_COMPANYNAME_RECVD'

export const ADD_LISTCOMPANY = 'ADD_LISTCOMPANY'
export const UPDATE_LISTCOMPANY = 'UPDATE_LISTCOMPANY'

export const RESPONSE_RECVD = 'RESPONSE_RECVD'
export const CLEAR_STATE = 'CLEAR_STATE'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_RESPIDENTIFIER = 'CLEAR_RESPIDENTIFIER'

export const SET_COMPANYID = 'SET_COMPANYID'
export const UPDATE_LISTUSER = 'UPDATE_LISTUSER'
export const ADD_LISTUSER = 'ADD_LISTUSER'
export const CLEAR_COMPANY_AND_ROLES = 'CLEAR_COMPANY_AND_ROLES'
export const DELETE_USER = 'DELETE_USER'

export const ERROR = 'ERROR'
export const KEEPALIVE_ERROR = 'KEEPALIVE_ERROR'
export const ADD = 'ADD'
export const UPDATE = 'UPDATE'

export const LOAD_FIELDS = 'LOAD_FIELDS'
export const RESET_FIELDS = 'RESET_FIELDS'
export const SET_FIELD_VALUE = 'SET_FIELD_VALUE'
export const SET_FIELD_METADATA = 'SET_FIELD_METADATA'
export const SET_ROLES_FIELD = 'SET_ROLES_FIELD'

export const COMPANYSTATE_RESET = 'COMPANYSTATE_RESET'
export const COMPANYSTATE_INITIALIZE = 'COMPANYSTATE_INITIALIZE'
export const COMPANYSTATE_REQUEST_COMPANYID_CHANGE = 'COMPANYSTATE_REQUEST_COMPANYID_CHANGE'
export const COMPANYSTATE_FINALIZE_COMPANYID_CHANGE = 'COMPANYSTATE_FINALIZE_COMPANYID_CHANGE'
export const COMPANYSTATE_CANCEL_COMPANYID_CHANGE = 'COMPANYSTATE_CANCEL_COMPANYID_CHANGE'
export const COMPANYSTATE_ROUTER_QUERY_CHANGE = 'COMPANYSTATE_ROUTER_QUERY_CHANGE'
export const COMPANYSTATE_UPDATE_COMPANY_ROLES = 'COMPANYSTATE_UPDATE_COMPANY_ROLES'
export const COMPANYSTATE_UPDATE_COMPANY_ROLES_ERROR = 'COMPANYSTATE_UPDATE_COMPANY_ROLES_ERROR'
export const COMPANYSTATE_UPDATE_COMPANIES = 'COMPANYSTATE_UPDATE_COMPANIES'
export const COMPANYSTATE_UPDATE_COMPANIES_ERROR = 'COMPANYSTATE_UPDATE_COMPANIES_ERROR'
export const COMPANYSTATE_UPDATE_USERS = 'COMPANYSTATE_UPDATE_USERS'
export const COMPANYSTATE_UPDATE_USERS_ERROR = 'COMPANYSTATE_UPDATE_USERS_ERROR'
export const COMPANYSTATE_UPDATE_USER = 'COMPANYSTATE_UPDATE_USER'
export const COMPANYSTATE_UPDATE_USER_ERROR = 'COMPANYSTATE_UPDATE_USER_ERROR'
export const COMPANYSTATE_REQUEST_USERID_CHANGE = 'COMPANYSTATE_REQUEST_USERID_CHANGE'
export const COMPANYSTATE_FINALIZE_USERID_CHANGE = 'COMPANYSTATE_FINALIZE_USERID_CHANGE'
export const COMPANYSTATE_UPDATE_INITIALIZING_FIELDSSTATE = 'COMPANYSTATE_INITIALIZE_FIELDSSTATE'
export const COMPANYSTATE_UPDATE_FIELDSSTATE = 'COMPANYSTATE_UPDATE_FIELDSSTATE'
export const COMPANYSTATE_CANCEL_USERID_CHANGE = 'COMPANYSTATE_CANCEL_USERID_CHANGE'
export const COMPANYSTATE_SUBMIT_REQUESTED = 'COMPANYSTATE_SUBMIT_REQUESTED'
export const COMPANYSTATE_SUBMIT_CANCELD_NO_CHANGES = 'COMPANYSTATE_SUBMIT_CANCELD_NO_CHANGES'
export const COMPANYSTATE_SUBMIT_CANCELD_INVALID = 'COMPANYSTATE_SUBMIT_CANCELD_INVALID'
export const COMPANYSTATE_SUBMIT_PERSIST_USER_REQUESTED = 'COMPANYSTATE_SUBMIT_PERSIST_USER_REQUESTED'
export const COMPANYSTATE_SUBMIT_PERSIST_USER_FINALIZED = 'COMPANYSTATE_SUBMIT_PERSIST_USER_FINALIZED'
export const COMPANYSTATE_SUBMIT_PERSIST_USER_ERROR = 'COMPANYSTATE_SUBMIT_PERSIST_USER_ERROR'
export const COMPANYSTATE_CLEAR_NO_CHANGES = 'COMPANYSTATE_CLEAR_NO_CHANGES'
export const COMPANYSTATE_CLEAR_SUCCESS = 'COMPANYSTATE_CLEAR_SUCCESS'

export const GET_INSTITUTESUMMARIES_REQUEST_SENT = 'GET_INSTITUTESUMMARIES_REQUEST_SENT'
export const GET_INSTITUTESUMMARIES_RECVD = 'GET_INSTITUTESUMMARIES_RECVD'
export const GET_INSTITUTESUMMARIES_ERROR = 'GET_INSTITUTESUMMARIES_ERROR'
export const CLEAR_GET_INSTITUTESUMMARIES_ERROR = 'CLEAR_GET_INSTITUTESUMMARIES_ERROR'
export const CLEAR_GET_INSTITUTESUMMARIES = 'CLEAR_GET_INSTITUTESUMMARIES'

export const GET_SUMMARIES_REQUEST_SENT = 'GET_SUMMARIES_REQUEST_SENT'
export const GET_SUMMARIES_RECVD = 'GET_SUMMARIES_RECVD'
export const GET_SUMMARIES_ERROR = 'GET_SUMMARIES_ERROR'
export const CLEAR_GET_SUMMARIES_ERROR = 'CLEAR_GET_SUMMARIES_ERROR'
export const CLEAR_GET_SUMMARIES = 'CLEAR_GET_SUMMARIES'

export const GET_PROJECTS_REQUEST_SENT = 'GET_PROJECTS_REQUEST_SENT'
export const GET_PROJECTS_RECVD = 'GET_PROJECTS_RECVD'
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR'
export const CLEAR_GET_PROJECTS_ERROR = 'CLEAR_GET_PROJECTS_ERROR'
export const CLEAR_GET_PROJECTS = 'CLEAR_GET_SUMMARIES'

export const UPDATE_REQUEST_SENT = 'UPDATE_REQUEST_SENT'
export const UPDATE_RECVD = 'UPDATE_RECVD'
export const UPDATE_ERROR = 'UPDATE_ERROR'
export const CLEAR_UPDATE_ERROR = 'CLEAR_UPDATE_ERROR'
export const CLEAR_UPDATE = 'CLEAR_UPDATE'

export const GET_VIDEO_REQUEST_SENT = 'GET_VIDEO_REQUEST_SENT'
export const GET_VIDEO_RECVD = 'GET_VIDEO_RECVD'
export const CLEAR_GET_VIDEO = 'CLEAR_GET_VIDEO'
export const GET_VIDEO_ERROR = 'GET_VIDEO_ERROR'
export const CLEAR_GET_VIDEO_ERROR = 'CLEAR_GET_VIDEO_ERROR'

export const GET_VIDEO_LIST_REQUEST_SENT = 'GET_VIDEO_LIST_REQUEST_SENT'
export const GET_VIDEO_LIST_RECVD = 'GET_VIDEO_LIST_RECVD'
export const CLEAR_GET_VIDEO_LIST = 'CLEAR_GET_VIDEO_LIST'
export const GET_VIDEO_LIST_ERROR = 'GET_VIDEO_LIST_ERROR'
export const CLEAR_GET_VIDEO_LIST_ERROR = 'CLEAR_GET_VIDEO_LIST_ERROR'

export const GET_FEATURES_REQUEST_SENT = 'GET_FEATURES_REQUEST_SENT'
export const GET_FEATURES_RECVD = 'GET_FEATURES_RECVD'
export const CLEAR_GET_FEATURES = 'CLEAR_GET_FEATURES'
export const GET_FEATURES_ERROR = 'GET_FEATURES_ERROR'
export const CLEAR_GET_FEATURES_ERROR = 'CLEAR_GET_FEATURES_ERROR'

export const GET_TMSYNCDATA_REQUEST_SENT = 'GET_TMSYNCDATA_REQUEST_SENT'
export const GET_TMSYNCDATA_RECVD = 'GET_TMSYNCDATA_RECVD'
export const GET_TMSYNCDATA_ERROR = 'GET_TMSYNCDATA_ERROR'
export const CLEAR_GET_TMSYNCDATA = 'CLEAR_GET_TMSYNCDATA'
export const CLEAR_GET_TMSYNCDATA_ERROR = 'CLEAR_GET_TMSYNCDATA_ERROR'

export const GET_TMADHOCDATA_REQUEST_SENT = 'GET_TMADHOCDATA_REQUEST_SENT'
export const GET_TMADHOCDATA_RECVD = 'GET_TMADHOCDATA_RECVD'
export const UPDATE_TMADHOCDATA_REQUEST_SENT = 'UPDATE_TMADHOCDATA_REQUEST_SENT'
export const UPDATE_TMADHOCDATA_RECVD = 'UPDATE_TMADHOCDATA_RECVD'
export const GET_TMADHOCDATA_ERROR = 'GET_TMADHOCDATA_ERROR'
export const UPDATE_TMADHOCDATA_ERROR = 'UPDATE_TMADHOCDATA_ERROR'
export const CLEAR_GET_TMADHOCDATA = 'CLEAR_GET_TMADHOCDATA'
export const CLEAR_UPDATE_TMADHOCDATA = 'CLEAR_UPDATE_TMADHOCDATA'
export const CLEAR_UPDATE_TMADHOCDATA_ERROR = 'CLEAR_UPDATE_TMADHOCDATA_ERROR'
export const CLEAR_GET_TMADHOCDATA_ERROR = 'CLEAR_GET_TMADHOCDATA_ERROR'
export const GET_WORKSTATIONLIST_REQUEST_SENT = 'GET_WORKSTATIONLIST_REQUEST_SENT'
export const GET_WORKSTATIONLIST_RECVD = 'GET_WORKSTATIONLIST_RECVD'

   