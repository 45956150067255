const initialRequestInterceptor = (request) => request
const initialResponseInterceptor = (response) => response

const AxiosInterceptor = (axiosInstanceParam) => {

        const axiosInstance = axiosInstanceParam;

        const setRequestInterceptor =  (interceptor) => {
        if (!axiosInstance || !axiosInstance.interceptors)
            return
        if (!interceptor)
        axiosInstance.interceptors.request.use(initialRequestInterceptor)
        else
        axiosInstance.interceptors.request.use(interceptor)
      };

      const setResponseInterceptor =  (interceptor) => {
        if (!axiosInstance || !axiosInstance.interceptors)
            return
        if (!interceptor)
          axiosInstance.interceptors.response.use(initialResponseInterceptor)
        else
          axiosInstance.interceptors.response.use(interceptor)
      };
    return {axiosInstance, setRequestInterceptor, setResponseInterceptor}

}

export default AxiosInterceptor
